
<!-- <footer class="page-footer">
  <div class="container">
    <div class="row">
      <div class="col l6 s12">
        <h5 class="white-text">LudiTICs Colombia</h5>
        <p class="grey-text text-lighten-4">Medellín - Colombia
          <br> Teléfono: +57-(311)0000000 
          <br> Email: luditicscolombia@gmail.com 
        </p>
      </div>
      <div class="col l4 offset-l2 s12">
        <h5 class="white-text">Links</h5>
        <ul>
          <li><a class="grey-text text-lighten-3" href="#!">Somos</a></li>
          <li><a class="grey-text text-lighten-3" href="#!">Lineas</a></li>
          <li><a class="grey-text text-lighten-3" href="#!">Contáctenos</a></li>
          <li><a class="grey-text text-lighten-3" href="#!">OTRO</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
    © 2022 Copyright  Todos los derechos reservados
    <a class="grey-text text-lighten-4 right" href="#!">More Links</a>
    </div>
  </div>
</footer> -->
            
<br>



<footer class="page-footer teal lighten-2" >
    <div class="center">
          <p class="white-text text-lighten-4">LudiTics <br>Medellín - Colombia
            <br> Teléfono: +57-(311)3720097 
            <br> Email: luditicscolombia@gmail.com </p>
          <a class="waves-effect waves-light btn-floating  facebook">
            Face</a>
              
            <a class="waves-effect waves-light btn-floating" target="_blank" rel="noopener"
             href="https://www.instagram.com/colombia.de.colores/?hl=es" title="Instagram">
              <i class="small material-icons " forecolor="red">access_alarm</i>
            </a>
            <a class="waves-effect waves-light btn-floating" target="_blank" rel="noopener"
             href="https://www.instagram.com/colombia.de.colores/?hl=es" title="Instagram">
              <i class="small material-icons " forecolor="red">queue_music</i>
            </a>

          <br>
    </div>
    <div class="footer-copyright">
      <div class="container center"> © 2022 Copyright Todos los derechos reservados. </div>
    </div>
  </footer>