<div class="container">
<div class="section">
<h5>Experiencia transformadora</h5>
    <ol>
        <li value="1">El encuentro: conocernos e identificar necesidades.</li>
        <li>Transformando: alcance de metas y procesos de formación.</li>
        <li>Integración de TICs: Adaptación de soluciones de integración tecnológica.</li>
        <li> Acompañamiento en la experiencia.</li>
        <li>Proyección institucional, (Servicios complementarios)</li>
    </ol>
</div>
</div>