<div class="container">
<h4>Líneas de actuación </h4>

  <div class="row">
    <div class="col s4 m4">
      <div class="card">
        <div class="card-image">
          <img src="./../../../assets/imagenes/experiencia.jpg">
        </div>
        <div class="card-content">
          <span class="card-title">Experiencia transformadora</span>
          <p>Te acompañamos para generar una experiencia transformadora hacia la calidad educativa y el logro de metas en el proceso de enseñanza - aprendizaje.</p>
        </div>
        <div class="card-action">
          <a [routerLink] = "'Seguimiento'">Ver más</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s4 m4">
        <div class="card">
          <div class="card-image">
            <img src="./../../../assets/imagenes/seguimiento.jpg">
          </div>
          <div class="card-content">
            <span class="card-title">Acompañamiento y Seguimiento Académico</span>
            <p>Ofrecemos una plataforma tecnológica para facilitar y cualificar la observación con un enfoque integral a grupos de personas.</p>
          </div>
          <div class="card-action">
            <a href="#">Ver más</a>
          </div>
        </div>
      </div>
  
    <div class="col s4 m4">
      <div class="card">
        <div class="card-image">
          <img src="./../../../assets/imagenes/banho.png">
        </div>
        <div class="card-content">
          <span class="card-title">Juego - Pensamiento matemático</span>
          <p>Generamos experiencias para el desarrollo y fortalecimiento del pensamiento matemático de una forma lúdica.</p>
        </div>
        <div class="card-action">
          <a href="#">Ver más</a>
        </div>
      </div>
    </div>

    <div class="col s4 m4">
      <div class="card">
        <div class="card-image">
          <img src="./../../../assets/imagenes/salon.jpg" >
        </div>
        <div class="card-content">
          <span class="card-title">Juego - Habilidades comunicativas</span>
          <p>Contamos con software para el desarrollo de habilidades comunicativas que transversaliza diversas disciplinas.</p>
        </div>
        <div class="card-action">
          <a href="#">Ver más</a>
        </div>
      </div>
    </div>
    <div class="col s4 m4">
      <div class="card">
        <div class="card-image">
          <img src="./../../../assets/imagenes/banner.jpg" >
        </div>
        <div class="card-content">
          <span class="card-title">Juego - Arte y Lúdica</span>
          <p>Integramos las TIC para el desarrollo de la inteligencia emocional y habilidades interpersonales por medio del arte y la lúdica.</p>
        </div>
        <div class="card-action">
          <a href="#">Ver más</a>
        </div>
      </div>
    </div>
    <div class="col s4 m4">
      <div class="card">
        <div class="card-image">
          <img src="./../../../assets/imagenes/articulo.jpg" >
        </div>
        <div class="card-content">
          <span class="card-title">Artículos</span>
          <p>Aquí encontrarás artículos académicos originados en las experiencias de validación de los servicios y productos.
            </p>
        </div>
        <div class="card-action">
          <a target="_blank" href="./../../../assets/articles/PI-DoShareAcademic.pdf">Ver Artículo</a>
        </div>
      </div>
    </div>
  </div>
</div>