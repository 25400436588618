import { Component } from "@angular/core";


@Component({
    selector: 'info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})

export class Info {

    public titulo: string;
    public comentario: string;
    public year: number;
    
    constructor(){
        this.titulo = "LudiTICs";
        this.comentario="Brindamos una experiencia transformadora para mejorar la calidad educativa por medio de las TICs,         la lúdica, la creatividad, la inclusión, la inteligencia emocional y procesos de desarrollo del pensamiento.";
        this.year = 2022;
        console.log("info componente cargado!");
        console.log(this.titulo, this.comentario, this.year);
    }
}