import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Info } from './components/info/info.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { InfoServiciosComponent } from './components/info-servicios/info-servicios.component';
import { FooterComponent } from './components/footer/footer.component';
import { SeguimientoComponent } from './components/seguimiento/seguimiento.component';

@NgModule({
  declarations: [
    AppComponent,
    Info,
    ContactoComponent,
    InfoServiciosComponent,
    FooterComponent,
    SeguimientoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
