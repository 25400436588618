
<!-- *cómo cambiar el color del texto en el navbar -->
<div class="navbar-fixed">
  <nav class="teal lighten-2 " role="navigation">
    <div class="nav-wrapper container">
      <a id="logo-container" href="#" class="brand-logo">LudiTICs Colombia</a>
      <ul class="right hide-on-med-and-down black-text">
        <li><a href="#" >Somos</a></li>
        <li><a href="#" >Lineas</a></li>
        <li><a href="#" >Contáctenos</a></li>
      </ul>

      <ul id="nav-mobile" class="sidenav">
        <li><a href="#">Somos</a></li>
        <li><a href="#">Lineas</a></li>
        <li><a href="#">Contáctenos</a></li>
      </ul>
      <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
    </div>
  </nav>
</div>

<div class="parallax-container" >
    <div class="section no-pad-bot">
      <div class="container">
        
        <h1 class="header center bold white-text"> {{titulo}} </h1>
        <br>
        <div class="row center white-text">
          <h5 class="header col s12 light"> {{ comentario }} </h5>
        </div>
      </div>
    </div>
    <div class="parallax"><img src="./../../../assets/imagenes/salon.jpg" width="900" ></div>
</div>

<div class="white black-text center">
  <div class="container">
    <div class="section">
      <p>
        Somos una empresa especializada en asesorías para el fortalecimiento de los procesos de enseñanza  integral con énfasis en uso de las TICS 
        y dirigida a organizaciones educativas.
        Contamos con una plataforma de software de apoyo al seguimiento de los estudiantes por parte  del profesor, 
        para facilitar la labor pedagógica y de acompañamiento  realizada por el docente. 
      </p>
    </div>
  </div>
</div>
